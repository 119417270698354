import { Link } from 'gatsby'
import React from 'react'
import Facebook from './icons/Facebook'
import Linkedin from './icons/Linkedin'
import Twitter from './icons/Twitter'

const FOOTER_LINKS = [
  {
    title: "Suggestions",
    route: "/suggestions"
  },
  {
    title: "Privacy Policy",
    route: "/privacy-policy"
  },
  {
    title: "User Agreement",
    route: "/user-agreement"
  },
]

const SOCIAL_MEDIA = [
  {
    "component": (<Facebook/>),
    "link": "/"
  },
  {
    "component": (<Linkedin/>),
    "link": "/"
  },
  {
    "component": (<Twitter/>),
    "link": "/"
  }
]

export default function Footer() {
  return (
    <footer className="bg-white dark:bg-gray-800 w-full py-8">
      <div className="max-w-screen-xl mx-auto px-4">
          <ul className="max-w-screen-md mx-auto text-lg font-light flex flex-wrap justify-between">
              {FOOTER_LINKS.map((link) => (
                  
                  <li 
                    className="my-2"
                    key={link.title}
                  >
                      <Link
                        className="text-gray-400 hover:text-gray-800 dark:text-gray-300 dark:hover:text-white transition-colors duration-200"
                        to={link.route}
                      >
                        {link.title}
                      </Link>
                  </li>
              ))}
          </ul>
          <div className="pt-8 flex max-w-xs mx-auto items-center justify-between">
            {
              SOCIAL_MEDIA.map(v=>(
                <a href={v.link} className="text-gray-400 hover:text-gray-800 dark:hover:text-white transition-colors duration-200">
                  {v.component}
                </a>
              ))
            }
          </div>
          <div className="text-center text-gray-500 dark:text-gray-200 pt-10 sm:pt-12 font-light flex items-center justify-center">
              © 2021 BiteSizedRecipes
          </div>
      </div>
  </footer>

  )
}
