import React, { useState } from 'react'
import { Link } from 'gatsby'
import logo from '../img/logo.svg'
import Menu from './icons/Menu';

const HOME_LINKS = [
  {
    title: "Home",
    route: "/"
  },
  {
    title: "About",
    route: "/about"
  },
  {
    title: "Blog",
    route: "/blog"
  },
  {
    title: "Recipes",
    route: "/recipes"
  },
  {
    title: "Contact",
    route: "/contact"
  },
]


export default function Navbar() {
  const [isExpanded, toggleExpansion] = useState(false);
  
  return (
    <header className="border-b-2 border-grey-400">
      <div className="flex flex-wrap items-center justify-between max-w-screen-xl p-4 mx-auto md:p-8">
        <Link to="/">
          <h1 className="flex items-center text-black no-underline">
            <img src={logo} alt="Bite Sized" className="h-12 sm:h-14 md:h-16"/>
          </h1>
        </Link>

        <button
          className="items-center block px-3 py-2 border text-black border-black rounded md:hidden"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <Menu/>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:items-center w-full md:w-auto`}
        >
          {HOME_LINKS.map((link) => (
            <Link
              className="block mt-4 text-lg  no-underline md:inline-block md:mt-0 md:ml-6"
              key={link.title}
              to={link.route}
            >
              {link.title}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  )
}
