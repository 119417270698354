
import React from 'react'
import Footer from './Footer'
import Navbar from "./Navbar"

export default function Layout({ children }) {
  return (  
    <div className="flex flex-col min-h-screen font-sans text-gray-900">
      <Navbar></Navbar>
      <main className="flex-1 w-full max-w-screen-xl px-4 py-8 mx-auto md:px-8 md:py-16">
        {children}
      </main>
      <Footer></Footer>
    </div>
  )
}


