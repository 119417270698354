import React from 'react'
import Layout from '../components/Layout'

export default function blog() {
  return (
    <Layout>
      
    </Layout>
  )
}
